import { useState } from "react";
import { CopyableCellSimple } from "../../../common/functions";

function PlTable(props: any) {
  let fundDetails = props.fundDetails;
  let analysis = props.analysis;

  return (
    <div style={{}} className=" fadeIn margin-top pnl-table-container">
      <table id="" className="table table-striped table-light table-hover  fund-table pl-table">
        <tbody>
          <tr className="sticky-top">
            <td>Type</td>
            <td>P&L (USD)</td>
            <td>Performance %</td>
            <td>Realizd (USD)</td>
            <td>Unrealized (USD)</td>
            <td>Int. Income (USD)</td>
            <td>Int. Income (USD) %</td>
            <td>FX P&L (USD)</td>
            <td>FX P&L (USD) %</td>
          </tr>
         <tr className="sticky-top">
  <td>Day (Gross)</td>

  <CopyableCellSimple text={(Math.round(fundDetails.daypl || 0)).toLocaleString()} />

  <CopyableCellSimple text={((fundDetails.dayplPercentage || 0).toLocaleString()) + " %"} />

  <CopyableCellSimple text={(Math.round(fundDetails.dayrlzd || 0)).toLocaleString()} />

  <CopyableCellSimple text={(Math.round(fundDetails.dayurlzd || 0)).toLocaleString()} />

  <CopyableCellSimple text={(Math.round(fundDetails.dayint || 0)).toLocaleString()} />

  <CopyableCellSimple text={((fundDetails.dayintPercentage || 0).toLocaleString()) + " %"} />

  <CopyableCellSimple text={(Math.round(fundDetails.dayfx || 0)).toLocaleString()} />

  <CopyableCellSimple text={`${(fundDetails.dayFX || 0).toFixed(3)} %`} />
</tr>

<tr className="sticky-top">
  <td>MTD (Gross || Net)</td>

  <CopyableCellSimple text={(Math.round(fundDetails.mtdpl || 0)).toLocaleString() + " || " + (Math.round(fundDetails.mtdplNet || 0)).toLocaleString()} />

  <CopyableCellSimple text={((fundDetails.mtdplPercentage || 0).toLocaleString()) + " % || " + ((fundDetails.mtdplNetPercentage || 0).toLocaleString()) + " %"} />

  <CopyableCellSimple text={(Math.round(fundDetails.mtdrlzd || 0)).toLocaleString()} />

  <CopyableCellSimple text={(Math.round(fundDetails.mtdurlzd || 0)).toLocaleString()} />

  <CopyableCellSimple text={(Math.round(fundDetails.mtdint || 0)).toLocaleString()} />

  <CopyableCellSimple text={((fundDetails.mtdintPercentage || 0).toLocaleString()) + " %"} />

  <CopyableCellSimple text={(Math.round(fundDetails.mtdfx || 0)).toLocaleString()} />

  <CopyableCellSimple text={`${(fundDetails.mtdFX || 0).toFixed(3)} %`} />
</tr>

<tr className="sticky-top">
  <td>YTD (Net)</td>
  <CopyableCellSimple text={"x"} />

  <CopyableCellSimple text={((fundDetails.ytdNet || 0).toLocaleString()) + " %"} />

  <CopyableCellSimple text={"x"} />

  <CopyableCellSimple text={"x"} />

  <CopyableCellSimple text={"YTD: " + (Math.round(fundDetails.ytdint || 0)).toLocaleString() + " || EST: " + (Math.round(fundDetails.ytdEstInt || 0)).toLocaleString()} />

  <CopyableCellSimple text={"YTD: " + ((fundDetails.ytdintPercentage || 0).toLocaleString()) + " %" + " || EST: " + ((fundDetails.ytdEstIntPercentage || 0).toLocaleString()) + " %"} />

  <CopyableCellSimple text={"x"} />

  <CopyableCellSimple text={"x"} />
</tr>

<tr className="sticky-top">
  <td>YTD (till previous month)</td>
  <CopyableCellSimple text={"x"} />
  <CopyableCellSimple text={fundDetails.ytpmnetperformance + " %" || "0%" } />
  <CopyableCellSimple text={"x"} />
  <CopyableCellSimple text={"x"} />
  <CopyableCellSimple text={(Math.round(fundDetails.ytpmint || 0)).toLocaleString()} />
  <CopyableCellSimple text={"x"} />
  <CopyableCellSimple text={"x"} />
  <CopyableCellSimple text={"x"} />
</tr>

<tr className="sticky-top">
  <td>Type</td>
  <td>Loan Amount (USD)</td>

  <td>Capacity Total (USD)</td>
  <td>Capacity IG (USD)</td>

  <td>Capacity HY (USD)</td>

  <td>Capacity Hedge (USD)</td>
  <td>DV01 Sum || CR01 Sum</td>
  <td>Starting NAV (End of {fundDetails.month || "Unknown"})</td>
  <td>Estimate NAV</td>
</tr>

<tr className="sticky-top">
  <td>Amount (USD)</td>
  <CopyableCellSimple text={(Math.round(fundDetails.borrowAmount || 0)).toLocaleString()} />

  <CopyableCellSimple text={(Math.round(analysis.capacity.amount || 0)).toLocaleString()} />
  <CopyableCellSimple text={(Math.round(analysis.capacity.amountIG || 0)).toLocaleString()} />
  <CopyableCellSimple text={(Math.round(analysis.capacity.amountHY || 0)).toLocaleString()} />
  <CopyableCellSimple text={(Math.round(analysis.capacity.amountHedge || 0)).toLocaleString()} />
  <CopyableCellSimple text={(Math.round(fundDetails.dv01Sum || 0)).toLocaleString() + " || " + (Math.round(fundDetails.cr01Sum || 0)).toLocaleString()} />
  <CopyableCellSimple text={(Math.round(fundDetails.nav || 0)).toLocaleString()} />
  <CopyableCellSimple text={(Math.round(fundDetails.shadawNAV || 0)).toLocaleString()} />
</tr>

        </tbody>
      </table>
    </div>
  );
}

export default PlTable;
